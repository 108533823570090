<template lang="pug">
include ../../../../configs/template
v-form(@submit.prevent="checkCoursePrice").p-2.row
  div.col-sm-12.col-md-6.col-lg-4.col-xl-3
    +date-picker-validation('dataForm.dateStart', 'dateEffective', 'dateStartObject', '["required", "maxValue", "minValue"]')
  div.col-sm-12.col-md-6.col-lg-4.col-xl-3
    +select-validation('dataForm.course', 'mappingCourses', 'course', 'labelName', '["required"]')
  div.col-sm-12.col-md-6.col-lg-4.col-xl-3
    +field-validation('dataForm.price', 'price', '["required", "minValue"]')(type="number" step="0.01")
  div.col-sm-12.col-md-6.col-lg-4.col-xl-3
    +select-validation('dataForm.formType', 'formTypeList', 'priceForm', 'lang', '["required"]')(item-value="value")
  div.w-100.text-center
    v-btn(color="success" :loading="buttonLoader" type="submit")  {{ $t('save') }}
</template>

<script>
import ValidationAlert from '@/components/atoms/FormComponents/ValidationAlert/ValidationAlert.vue'
import { dateFormat } from '@/mixins/main'
import { mapActions, mapState } from 'vuex'
import { coursePrice } from '@/mixins/validationRules'
function formFieldsInitialState () {
  return {
    dateStart: null,
    course: null,
    price: null,
    formType: null
  }
}

export default {
  name: 'BackOfficeCoursePricesAdd',
  data () {
    return {
      dataForm: formFieldsInitialState(),
      dateTomorrow: null,
      buttonLoader: false,
      formTypeList: [
        {
          id: 1,
          ua: 'Ф1 (грн.)',
          en: 'F1 (uah.)',
          value: 'First'
        },
        {
          id: 2,
          ua: 'Ф2 ($)',
          en: 'F2 ($)',
          value: 'Second'
        }
      ]
    }
  },
  components: {
    ValidationAlert
  },
  props: {
    getDocuments: Function
  },
  computed: {
    ...mapState({
      lang: state => state.main.lang,
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      // mapping document
      mappingCourses: state => state.directory.courses
    }),
    dateStartObject () {
      return this.dataForm.dateStart ? new Date(this.dataForm.dateStart) : null
    }
  },
  mounted () {
    // Get tomorrow's date
    let tomorrow = new Date()
    tomorrow.setDate(new Date().getDate() + 1)
    this.dateTomorrow = dateFormat(tomorrow)
  },
  validations () { return coursePrice(this._data) },
  methods: {
    ...mapActions(['postCoursePriceOnline']),
    /** Check fields entries validation */
    checkCoursePrice () {
      if (this.$v.$invalid) {
        return this.$v.$touch()
      } else this.addCoursePrice()
    },

    /** Add ETI course prise */
    async addCoursePrice () {
      this.buttonLoader = true
      const body = {
        date_start: this.dataForm.dateStart,
        course: this.dataForm.course,
        price: parseFloat(this.dataForm.price),
        type_of_form: this.dataForm.formType
      }
      const response = await this.postCoursePriceOnline({ body })
      this.buttonLoader = false
      if (response.code === 201) {
        this.$notification.success('coursePriceAdded')
        this.$parent.isViewAddSlot = false
        this.$data.dataForm = formFieldsInitialState()
        this.getDocuments()
      } else this.$notification.error('existCoefficient')
    }
  }
}

</script>
